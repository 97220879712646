import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { blue, purple, teal } from "@material-ui/core/colors";
import { GetData } from "../../api/service";

const styles = (theme) => ({
  root: {
    padding: "20px",
  },
  card: {
    boxShadow: "1px 1px 3px rgba(0,0,0,0.1)",
    paddingLeft: "10px",
    borderRadius: "0px",
  },
  cardNumber: {
    fontSize: 40,
    fontWeight: "100",
    color: "inherit",
  },
  gridOwn: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  gridSmallLeft: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
  },
});

class Stats extends Component {
  state = {
    current_balance: 0.0,
    op_bal: 0.0,
    cr_bal: 0.0,
    user_count: 0,
    debit: 0,
    credit: 0,
    total_earning: 0,
    total_tds: 0,
    total_admin: 0,
    repurchase_wallet: 0,
    total_payout: 0,
    total_payout_given: 0,
  };

  componentDidMount() {
    // GetData(`/${this.props.apikey}/${this.props.username}/companybalance`).then(
    //   (resp) => {
    //     if (resp.length > 0) {
    //       let current_balance = resp[0].cr_bal;
    //       this.setState({
    //         current_balance,
    //       });
    //     }
    //   }
    // );

    GetData(`/get-dashboard-admin`).then((resp) => {
      if (resp != false) {
        this.setState({
          ...resp,
        });
      }
    });

    // GetData(`/${this.props.apikey}/companyincome`).then((resp) => {
    //   this.setState({
    //     debit: resp.debit,
    //     credit: resp.credit,
    //   });
    // });

    // GetData(
    //   `/${this.props.apikey}/${this.props.username}/companybalancedaily`
    // ).then((resp) => {
    //   // console.log(resp);
    //   if (resp.length > 0) {
    //     this.setState({
    //       op_bal: resp[0].op_bal,
    //       cr_bal: resp[0].cr_bal,
    //     });
    //   }
    // });

    GetData(`/${this.props.apikey}/${this.props.username}/gettotaluser`).then(
      (resp) => {
        //console.log(resp);
        this.setState({
          user_count: resp,
        });
      }
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.gridOwn}>
          <div className={classes.gridSmallLeft}>
            <DetailsCard
              {...this.props}
              background={`#283747`}
              color="lightgrey"
              label={`Current Active Users`}
              value={this.state.user_count}
            />

          <DetailsCard
              {...this.props}
              background={`#283747`}
              color="lightgrey"
              label={`Total Turnover`}
              value={parseFloat(parseFloat(806) * 3000 + (parseFloat(this.state.user_count) - 806) * 2200).toFixed(2)}
            />

            <DetailsCard
              {...this.props}
              background={"#2471A3"}
              color="white"
              label={`Total Income Generated`}
              value={parseFloat(this.state.total_earning).toFixed(2)}
            />

            <DetailsCard
              {...this.props}
              background={"#4A235A"}
              color="white"
              label={`Total TDS Collected`}
              value={parseFloat(this.state.total_tds).toFixed(2)}
            />

            <DetailsCard
              {...this.props}
              background={"#633974"}
              color="white"
              label={`Total Admin Amount`}
              value={parseFloat(this.state.total_admin).toFixed(2)}
            />

            <DetailsCard
              {...this.props}
              background={"#E74C3C"}
              color="white"
              label={`Total Repurchase Wallet`}
              value={parseFloat(this.state.repurchase_wallet).toFixed(2)}
            />

            <DetailsCard
              {...this.props}
              background={teal[300]}
              color="white"
              label={`Net Income Generated`}
              value={parseFloat(this.state.total_payout).toFixed(2)}
            />

<DetailsCard
              {...this.props}
              background={"#A04000"}
              color="white"
              label={`Total Payout Given`}
              value={parseFloat(this.state.total_payout_given).toFixed(2)}
            />
          </div>

          <div className={classes.gridSmallRight}>
            <DetailsCard {...this.props} background={``} color="lightgrey" />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Stats);

export const DetailsCard = (props) => {
  let { classes } = props;
  return (
    <div>
      <Card
        className={classes.card}
        style={{ backgroundColor: props.background, color: props.color }}
      >
        <CardContent>
          <Typography className={classes.cardNumber}>{props.value}</Typography>
          <Typography
            variant="subheading"
            color="inherit"
            className={classes.cardNumberText}
          >
            {props.label}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
